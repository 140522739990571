@import "~@/styles/variables/variables.scss";



























.device-develop {
	height: 100%;
}
